<template>
    <li v-if="userStore.loggedIn" class="nav-item">
        <div class="dropdown" x-data="ToggleVisibility">
            <div class="header__user">
                <div class="header__user__item dropdown-toggle" x-on:click="toggle">
                    <span>{{ userStore.user.firstname }} {{ userStore.user.lastname }}</span>
                    <IconLogin />
                    <IconChevron />
                </div>
            </div>

            <ul class="dropdown-menu">
                <li>
                    <a :href="myAccountUrl" class="dropdown-item">
                        {{ $t('labels.manage_account') }}
                    </a>
                </li>
                <li>
                    <a :href="userStore.logoutUrl" class="dropdown-item">
                        {{ $t('labels.logout') }}
                        <IconLogout />
                    </a>
                </li>
            </ul>
        </div>
    </li>

    <li v-else class="nav-item">
        <Stack>
            <template #toggler="{ show }">
                <div class="header__user">
                    <div class="header__user__item" @click="show">
                        <span>{{ $t('labels.login') }}</span>
                        <IconLogin />
                    </div>
                </div>
            </template>

            <template #content>
                <UserAuth />
            </template>
        </Stack>
    </li>
</template>

<script setup lang="ts">
import { useUserStore } from '@/vue/store/user';
import Stack from '@/vue/components/Stack.vue';
import UserAuth from '@/vue/components/auth/UserAuth.vue';
import IconLogin from '@/svg/icon-login.svg?component';
import IconChevron from '@/svg/icon-chevron-down.svg?component';
import IconLogout from '@/svg/icon-logout.svg?component';
import { ref } from 'vue';

const userStore = useUserStore();
const showUserMenu = ref(false);

defineProps({
    myAccountUrl: {
        type: String,
        required: true,
    },
});

const show = () => {
    showUserMenu.value = !showUserMenu.value;
};
</script>

<style scoped>
.dropdown-item {
    white-space: nowrap;
}
</style>
