<template>
    <div class="popup__wrapper popup__full_width">
        <div class="popup__inner">
            <div class="popup__x popup--closer" @click="emit('close')">
                <IconX />
            </div>
            <div class="popup__content">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-xl-10 offset-xl-1">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup__green popup--closer" @click="emit('close')"></div>
    </div>
</template>

<script lang="ts" setup>
import IconX from '@/svg/icon-x.svg?component';
const emit = defineEmits(['close']);
</script>
