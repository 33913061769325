<template>
    <Autocomplete
        :placeholder="$t('labels.search_placeholder')"
        :iconLoupe="true"
        :loadAfterSelection="true"
        :min-length="3"
        :site-handle="siteHandle"
        apiUrl="/api/search/autocomplete"
        #default="{ autocompleteData, closeAutocomplete, selectAutocomplete }"
        @onEnter="redirectToSearchPage"
        @clickOnIcon="redirectToSearchPage"
        @change="searchString = $event"
    >
        <div v-if="autocompleteData" v-click-outside="closeAutocomplete" class="search-result__autocomplete">

            <div class="search-result__row text--normal">
                <div class="search-result__col col--suggest" v-if="showSuggestion && autocompleteData.suggestionHits && autocompleteData.suggestionHits.length > 0 || showSpellcheck && autocompleteData.spellcheckHits && autocompleteData.spellcheckHits.length > 0">
                    <div class="search-result__col__inner" v-if="showSuggestion && autocompleteData.suggestionHits && autocompleteData.suggestionHits.length > 0">
                        <div class="title--small">{{ $t('labels.search_suggest') }}</div>
                        <div class="search-result__link" v-for="suggestionHit in autocompleteData.suggestionHits">
                            <div @click="selectAutocomplete(suggestionHit.term)">{{ suggestionHit.term }}</div>
                        </div>
                    </div>

                    <div class="search-result__col__inner" v-if="showSpellcheck && autocompleteData.spellcheckHits && autocompleteData.spellcheckHits.length > 0">
                        <div class="title--small">{{ $t('labels.search_spellcheck') }}</div>
                        <div class="search-result__link" v-for="spellcheckHit in autocompleteData.spellcheckHits">
                            <div @click="selectAutocomplete(spellcheckHit.term)">{{ spellcheckHit.term }}</div>
                        </div>
                    </div>
                </div>
                <div class="search-result__col" v-if="autocompleteData.hits.length > 0">
                    <div class="title--small">{{ $t('labels.search_suggestions_by_subject_area') }}</div>
                    <div class="search-result__group" v-for="groupHit in autocompleteData.hits">
                        <div class="search-results__theme-titles">{{ $t('labels.in') }}
                            &laquo;{{ groupHit.group.value }}&raquo;:
                        </div>
                        <div class="search-result__link" v-for="hit in groupHit.hits">
                            <a :href="hit.url"><span v-html="hit.search_highlight.title"/></a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Autocomplete>
</template>

<script lang="ts" setup>
import Autocomplete from '@/vue/elements/Autocomplete.vue';
import { ref } from 'vue';

const props = defineProps({
    placeholder: {
        type: String,
        required: true,
    },
    siteHandle: {
        type: String,
        required: true,
    },
    searchPageUrl: {
        type: String,
        required: false,
    },
    showSuggestion: {
        type: Boolean,
        required: false,
    },
    showSpellcheck: {
        type: Boolean,
        required: false,
    },
});

const searchString = ref<string>('');

const redirectToSearchPage = () => {
    if(props.searchPageUrl) {
        let location = props.searchPageUrl;
        if (searchString.value) {
            location += '?q=' + searchString.value;
        }

        window.location.href = location;
    }
}
</script>
