<template>
    <div class="input__wrapper">
        <label v-if="props.label" class="text--legend">{{ props.label }}</label>
        <div class="input__icon form-group">
            <input
                v-model="searchValue"
                @keyup.enter="onEnter"
                @keyup="getAutocompleteData"
                @keyup.down="focusNextItem"
                @keyup.up="focusPreviousItem"
                type="text"
                :placeholder="props.placeholder"
                class="form-control search__input"
            />
            <div class="search__action-wrapper">
                <div class="search__emptyer" v-if="searchValue" @click="clearInput">
                    <IconX />
                </div>

                <div class="search__loupe" v-if="props.iconLoupe" @click="emit('clickOnIcon')">
                    <IconLoupe />
                </div>
            </div>
        </div>

        <slot
            v-if="show"
            :autocompleteData="autocompleteData"
            :focusedIndex="focusedIndex"
            :closeAutocomplete="closeAutocomplete"
            :selectAutocomplete="selectAutocomplete"
        ></slot>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import axios from 'axios';
import IconLoupe from '@/svg/icon-loup-middle.svg?component';
import IconX from '@/svg/icon-x-small.svg?component';
import { debounce } from 'lodash';

const emit = defineEmits(['change', 'onEnter', 'clickOnIcon', 'changeLive']);

let controller = new AbortController();

const props = defineProps({
    placeholder: {
        type: String,
        required: true,
    },
    minLength: {
        type: Number,
        required: true,
    },
    onlyFireOnSelection: {
        type: Boolean,
        required: false,
    },
    iconLoupe: {
        type: Boolean,
        required: false,
    },
    label: {
        type: String,
        required: false,
    },
    apiUrl: {
        type: String,
        required: false,
    },
    siteHandle: {
        type: String,
        required: false,
    },
    loadAfterSelection: {
        type: Boolean,
        required: false,
    },
    searchValueModel: {
        type: String,
        required: false,
    },
    closeOnEnter: {
        type: Boolean,
        required: false,
    },
    focusIndex: {
        type: Boolean,
        required: false,
    },
});

const show = ref(false);
const searchValue = ref(props.searchValueModel ?? '');
const autocompleteData = ref<Array<String>>([]);
const ajaxRunning = ref(false);
const focusedIndex = ref<number|null>(null);

const doRequest = debounce(async (term: string) => {
    if (searchValue.value.length > props.minLength) {
        if (!props.onlyFireOnSelection) {
            emit('change', term);
        }

        if (props.apiUrl) {
            controller.abort();
            controller = new AbortController();

            ajaxRunning.value = true;

            try {
                const response = await axios.get<any>(props.apiUrl, {
                    signal: controller.signal,
                    params: {
                        search: term,
                        siteHandle: props.siteHandle,
                    },
                });
                autocompleteData.value = response.data;
            } catch (_) {
                //
            }

            ajaxRunning.value = false;
            show.value = true;
            if (autocompleteData.value.length > 0) {
                focusedIndex.value = 0;
            }
            else {
                focusedIndex.value = null;
            }
        } else {
            show.value = false;
        }

        if (!props.onlyFireOnSelection) {
            show.value = term.length > props.minLength;
        }
    }
}, 50);

const getAutocompleteData = async (e?) => {
    if (!e || (e.which !== 13 && e.which !== 38 && e.which !== 40)) {
        doRequest(searchValue.value);
        emit('changeLive', searchValue.value);
    }
};

const selectAutocomplete = (autocompleteValue: any) => {
    searchValue.value = autocompleteValue;
    autocompleteData.value = [];

    if (props.onlyFireOnSelection) {
        emit('change', searchValue.value);
    }

    if (props.loadAfterSelection) {
        getAutocompleteData();
    }
};

const selectFocusedItem = () => {
    if (focusedIndex.value === null) {
        return;
    }
    selectAutocomplete(autocompleteData.value[focusedIndex.value.toString()]);
};
const closeAutocomplete = () => {
    autocompleteData.value = [];
    show.value = false;
};

const onEnter = () => {
    emit('onEnter');
    if (props.focusIndex) {
        selectFocusedItem();
        closeAutocomplete();
        return;
    }
    if (props.closeOnEnter) {
        closeAutocomplete();
    }
};

const focusPreviousItem = () => {
    if (props.focusIndex === false || focusedIndex.value === null) {
        return;
    }
    if (focusedIndex.value > 0) {
        focusedIndex.value = focusedIndex.value - 1;
    }
};

const focusNextItem = () => {
    if (props.focusIndex === false || focusedIndex.value === null) {
        return;
    }
    if (autocompleteData.value.length > focusedIndex.value + 1) {
        focusedIndex.value = focusedIndex.value + 1;
    }
};

const clearInput = () => {
    searchValue.value = '';
};

watch(
    () => props.searchValueModel,
    (newValue: String) => {
        searchValue.value = newValue;
    }
);
</script>
