import $ from 'jquery';

export const Teich = () => {
    const hoveredItems = $('[class*="hovered"]');

    hoveredItems.each(function () {
        $(this).removeClass('.hovered');
    });

    // Open Main Navigaton
    $('.main__navigation li.nav-menu > a').click(function (e) {
        e.preventDefault();
        if ($(this).closest('.hovered').hasClass('hovered')) {
            $(this).closest('.hovered').removeClass('hovered');
            $('.overlay__closer').fadeOut();
        } else {
            $(this).parent().addClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
            $('.overlay__closer').fadeIn();
        }
    });
    $('.drop-menu__ul > .submenu__inner > li.parent > a').click(function (e) {
        e.preventDefault();
        $(this).parent().addClass('hovered');
        $(this).parent().siblings().removeClass('hovered');
    });
    $('.menu--closer, .overlay__closer').click(function () {
        $('.main__navigation li.nav-menu ').removeClass('hovered');
        $('.overlay__closer').fadeOut();
    });

    // Menü Hight Calc
    $('.main__navigation li.nav-menu').click(function () {
        var t = 0; // the height of the highest element (after the function runs)
        var childElem = $(this).find('.submenu__wrapper');
        $(childElem).each(function () {
            var elem = $(this);
            elem.height('auto');
            if (elem.height() > t) {
                t = elem.height();
            }
        });
        $(childElem).height(t);
    });

    // Select opener
    // $('.form-select').click(function () {
    //     handleSelectClick(this);
    // });

    // Footer Mobile Accordeon
    $('.footer__links__title').click(function () {
        $(this).siblings('.footer-links').slideToggle();
        $(this).toggleClass('active');
    });

    // Header Controller
    $('.page-section.bg-light').prev('.page__header').addClass('light-section-next');

    function isScrolledIntoView(elem) {
        const docViewTop = $(window).scrollTop();
        const elemTop = $(elem).offset().top;

        return elemTop <= docViewTop;
    }

    // Make fixed Navigation
    var lastScrollTop = 0;
    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 300) {
            $('body').addClass('fixed-header');
        } else {
            $('body').removeClass('fixed-header');
        }

        // Add Class when scrolling one window height
        var st = $(this).scrollTop();
        if (st > lastScrollTop) {
            if ($(window).scrollTop() > window.innerHeight) {
                $('body').addClass('viewport--left');
            } else {
                $('body').removeClass('viewport--left');
            }
        } else {
            $('body').removeClass('viewport--left');
        }
        lastScrollTop = st;

        // Is Element in viewport
        $('.course__list__table').each(function () {
            if (isScrolledIntoView(this) === true) {
                $('body').addClass('course-header--active');
            } else {
                $('body').removeClass('course-header--active');
            }
        });
    });
};
