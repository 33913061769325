<template>
    <div class="form-group">
        <div class="form-floating" :class="classNames">
            <input
                ref="el"
                v-bind="$attrs"
                :id="`input-${id}`"
                :type="type"
                class="form-control field--password"
                :class="classNames"
                :name="name"
                v-model="field.value.value"
                :placeholder="display"
                @blur="field.setTouched(true)"
            />
            <label :for="`input-${id}`">{{ display }}</label>
            <div class="input__icon" @click="togglePlainText">
                <IconEye v-if="type === 'password'" />
                <IconEyeClose v-if="type === 'text'" />
            </div>
        </div>

        <div class="invalid-feedback" v-show="error">{{ error }}</div>
        <div class="input-description" v-show="!error && description">{{ description }}</div>
    </div>
</template>

<script lang="ts" setup>
import {inject, ref, toRefs} from 'vue';
import { useFormField } from '@/vue/components/form/helper';
import { FormContext } from 'vee-validate';
import { FormContextKey } from '@/vue/keys';
import IconEye from '@/svg/icon-eye.svg?component';
import IconEyeClose from '@/svg/icon-eye-closed.svg?component';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        required: false,
        default: () => false,
    },
});

const form: FormContext = inject(FormContextKey)!;
const { name = '', label, required } = toRefs(props);

const { id, field, display, path, value, error, classNames } = useFormField({
    name: name,
    label: label,
    required: required,
    form: form,
});

const type = ref<string>('password');

const togglePlainText = () => {
    type.value = type.value === 'password' ? 'text' : 'password';
}
</script>
