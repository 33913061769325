<template>
    <div v-if="responseStatus == ''">
        <div class="login__reset-password">
            <div class="h2">
                {{ $t('labels.reset_password') }}
            </div>
            <div class="text--normal">{{ $t('labels.reset_password_text') }}</div>
            <form class="reser-password__form" @submit="onResetPassword">
                <TextField :label="$t('labels.email')" name="email" type="email" :required="true" />

                <div class="login__reset-password__footer">
                    <button class="btn btn-primary" type="submit">
                        {{ $t('labels.submit') }}
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div v-if="responseStatus == 'success' || responseStatus == 'error'">
        <div class="login__message login--success">
            <div class="h2">
                <IconOk />
                {{ $t('labels.request_sent') }}
            </div>
            <div class="text--normal">
                {{ $t('labels.password_reset_link_sent') }}
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import TextField from '@/vue/components/form/TextField.vue';
import { useForm } from 'vee-validate';
import { provide, ref } from 'vue';
import { FormContextKey } from '@/vue/keys';
import * as yup from 'yup';
import axios from 'axios';
import IconOk from '@/svg/icon-ok.svg?component';
import { useUserStore } from '@/vue/store/user';

const form = useForm<Required<any>>({
    validationSchema: yup.object({
        email: yup.string().email().max(200).required()
    }),
    keepValuesOnUnmount: true,
    validateOnMount: true
});

provide(FormContextKey, form);

const userStore = useUserStore();

const responseStatus = ref<string>('');

const onResetPassword = form.handleSubmit(async (values) => {
    await axios
        .post<any>('/!/auth/password/email', {
            ...values,
            _reset_url: userStore.resetUrl
        })
        .then(() => {
            responseStatus.value = 'success';
        })
        .catch(() => {
            responseStatus.value = 'error';
        });
});
</script>
