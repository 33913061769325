<template>
    <div class="header__search__item">
        <span @click="modalVisible = true">{{ $t('labels.search') }}</span>
        <IconLoupe @click="modalVisible = true" />
    </div>
    <search-global-modal v-if="modalVisible" :site-handle="siteHandle" :search-page-url="searchPageUrl" @close="modalVisible = false" />
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import IconLoupe from '@/svg/icon-loupe.svg?component';
import SearchGlobalModal from '@/vue/components/search/global/SearchGlobalModal.vue';

defineProps({
    siteHandle: {
        type: String,
        required: true,
    },
    searchPageUrl: {
        type: String,
        required: true,
    },
});

const modalVisible = ref(false);

watch(modalVisible,(visible: Boolean) => {
    document.body.classList.toggle('globalsearch--open')
});
</script>
