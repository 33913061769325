import $ from 'jquery';

export default (container?: HTMLElement) => {
    (container ? [container] : document.querySelectorAll('.app-accordion')).forEach((el) => {
        const items = el.querySelectorAll('.accordion-item');

        const makeItemActive = (activeItem: Element) =>
            items.forEach((item) => {
                if (item.classList.contains('active') || item !== activeItem) {
                    item.classList.remove('active');
                    $(item.querySelector('.accordion-content')!).slideUp({
                        duration: 300
                    });
                } else {
                    item.classList.add('active');
                    $(item.querySelector('.accordion-content')!).slideDown({
                        duration: 300
                    });
                }
            });

        items.forEach((item) => {
            if ((item as any).hasAccordion === true) {
                return;
            }

            (item as any).hasAccordion = true;
            item.querySelector('.accordion-item-toggle')?.addEventListener('click', () => makeItemActive(item));
        });
    });
};
