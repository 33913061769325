import { ErrorMessage, Field, Form } from 'vee-validate';
import { App, defineAsyncComponent } from 'vue';
import MenuLoginButton from '@/vue/components/auth/MenuLoginButton.vue';
import SearchGlobalButton from '@/vue/components/search/global/SearchGlobalButton.vue';

export const registerComponents = (app: App<Element>) => {
    app.component(
        'app-course-registration-button',
        defineAsyncComponent(() => import('@/vue/components/courses/registration/CourseRegistrationButton.vue')),
    );
    app.component(
        'app-courses-overview',
        defineAsyncComponent(() => import('@/vue/components/courses/overview/CoursesOverview.vue')),
    );
    app.component(
        'app-search',
        defineAsyncComponent(() => import('@/vue/components/search/Search.vue')),
    );
    app.component(
        'app-user-auth',
        defineAsyncComponent(() => import('@/vue/components/auth/UserAuth.vue')),
    );

    app.component('app-menu-login-button', MenuLoginButton);
    app.component('app-search-global', SearchGlobalButton);
    app.component('Form', Form);
    app.component('Field', Field);
    app.component('ErrorMessage', ErrorMessage);
};
