import { RunThemeExtensions } from 'wly-statamic-theme-extensions';
import { AlpineComponents } from '@/alpine';

import './alpine/index';
import './scripts/index';
import ClickOutside from '@/vue/directives/clickOutsideDirective';
import { registerComponents } from '@/vue/components';
import { App } from 'vue';

RunThemeExtensions({
    vue: {
        onBootstrap: (app: App<Element>) => {
            registerComponents(app);

            app.directive('click-outside', ClickOutside);

            // todo: fix translations in theme-extensions
            void import('./vue/validation')
        },
        i18n: {
            //
        },
    },
    alpine: {
        enableLivewire: true,
        components: AlpineComponents,
    },
    isotope: {
        //
    },
    menu: {
        configs: {
            screenReader: {
                text: {
                    closeSubmenu: 'Back',
                },
            },
        },
    },
    tools: {
        //
    },
});
